import React, { createRef, Component } from 'react';
import classNames from 'classnames';
import {observer} from "mobx-react";
import {StoreContext} from "../../stores/StoreLoader";
import LeafletMap from "../utilities/LeafletMap";


@observer
class InteractiveCollectionMap extends Component {
    static contextType = StoreContext;

    constructor(props) {
        super(props);

        this.mapRef = createRef();
    }

    render() {
        const { gridStore } = this.context;

        let mapComponent, marker, blockModel, category;
        if (gridStore.shouldShowMap) {
            const markers = [];
            gridStore.visibleBlockIds.forEach((blockId) => {
                blockModel = gridStore.blocks.get(blockId).blockModel;
                category = blockModel.all_categories[0];

                blockModel.locations.forEach((location) => {
                    if (location.latitude !== null &&
                        location.latitude !== 0 &&
                        location.longitude !== null &&
                        location.longitude !== 0) {
                        marker = {
                            coordinates: location.coordinates,
                            category: {
                                alias: category.alias,
                                title: category.title,
                                icon_url: category.icon_url,
                            }
                        }
                        markers.push(marker);
                    }
                });
            });

            mapComponent = <LeafletMap key="leafletmap" locations={markers} markerOutline={true}/>;
        }

        const mapBoxClassNames = classNames({
            'active': gridStore.shouldShowMap,
        });

        return (
            <div id="sb-organizations-map" ref={this.mapRef} className={mapBoxClassNames}>
                { mapComponent }
            </div>
        );
    }
}

export default InteractiveCollectionMap
